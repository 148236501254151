<template>
  <!-- Delete Syllabus Template Modal -->
  <div class="modal fade" id="deleteSyllabusTemplateModal" tabindex="-1"
       aria-labelledby="deleteSyllabusTemplateModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="deleteSyllabusTemplateModalLabel">Удаление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="col-md-12 mt-4">
            <p>Вы точно хотите удалить этот шаблон?</p>
          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="deleteSyllabus">
            Удалить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Delete Syllabus Template Modal -->
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "DeleteSyllabusTemplateModal",

  data() {
    return {

    }
  },
  computed: {
    ...mapState('syllabus', ['syllabus_form']),

  },

  methods: {
    ...mapActions('syllabus', ['DELETE_SYLLABUS_DATA','GET_SYLLABUS_DATA']),
    async deleteSyllabus() {
      await this.DELETE_SYLLABUS_DATA()
      //     .then(res => {
      //   if (res.success == true) {
      //     this.GET_SYLLABUS_DATA();
      //     this.$message({title: 'Удаление', text: 'Данные успешно удалились'})
      //   } else {
      //     let errorText = '';
      //     for (let err of res.errors[0]) {
      //       errorText += err.message + '\n'
      //     }
      //     this.$error({title: 'Удаление', text: errorText})
      //   }
      // })

      this.$message({title: 'Удаление', text: 'Успешное удаление'})
      await this.GET_SYLLABUS_DATA()
    }
  },
  async mounted() {

  }
}
</script>

<style scoped>
.big-checkbox {
  width: 20px;
  height: 20px;
}
</style>