<template>
  <!-- Add Syllabus Template Modal -->
  <div class="modal fade" id="addSyllabusTemplate" tabindex="-1"
       aria-labelledby="addSyllabusTemplateLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addSyllabusTemplateLabel">Добавление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  @click="closeAddSyllabusModal"></button>
        </div>
        <div class="modal-body">

          <div class="col-md-12 mt-4">
            <!--            v-for="(newSyllabusInfo, index) in syllabus_form.newSyllabusInfos"-->
            <!--            :key="index"-->
            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Поиск дисциплины</label>
              <div class="col-md-9">
                <DropdownListEducationDiscipline
                  :options="options"
                  @search="onSearch"
                  @change="changeSelectedEducationDiscipline($event)"
                />
              </div>
              <div class="col-md-12">
                <button type="button" class="btn btn-primary" @click="selectDiscipline">Выбрать</button>
              </div>
            </div>


            <div class="discipline_info_selected" v-if="isDisciplineSelected">
              <div class="discipline_info" v-for="(item, index) in syllabus_form.educationDisciplineInfos" :key="index">
                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Кафедра</label>
                  <div class="col-md-9">
                    <p>{{ item.department.name_ru }}</p>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Язык обучения</label>
                  <div class="col-md-9">
                    <p>{{ item.language.native_name }}</p>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Уровень обучения</label>
                  <div class="col-md-9">
                    <p>{{ item.studyLevel.name }}</p>
                  </div>
                </div>

<!--                <div class="form-group row mt-4">-->
<!--                  <label for="prerequisites" class="col-md-3 col-form-label">Пререквизиты</label>-->
<!--                  <div class="col-md-9">-->
<!--                    <textarea id="prerequisites" type="text" class="form-control" placeholder="Пререквизиты"-->
<!--                              @input="changeNewSyllabusData('discipline_prerequisite', $event)">-->
<!--                    </textarea>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="form-group row mt-4">-->
<!--                  <label for="postrequisites" class="col-md-3 col-form-label">Постреквизиты</label>-->
<!--                  <div class="col-md-9">-->
<!--                    <textarea id="postrequisites" type="text" class="form-control" placeholder="Постреквизиты"-->
<!--                              @input="changeNewSyllabusData('discipline_postrequisite', $event)">-->
<!--                      </textarea>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Периодичность предложения </label>
                  <div class="col-md-9">
                    <p>Один семестр</p>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Длительность</label>
                  <div class="col-md-9">
                    <div v-if="selectedEducationDisciplineDepartmentId == 5">
                      <p>10 недель</p>
                    </div>
                    <div v-else>
                      <p>15 недель</p>
                    </div>

                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Количество часов за семестр</label>
                  <div class="col-md-2">
                    Лекция - {{item.creditSum[1]}}
                    <!--                  <input id="lecture_hour" type="text" class="form-control" placeholder="Лекция">-->
                  </div>
                  <div class="col-md-2">
                    СРСП(СРМП) - {{item.creditSum[5]}}
                    <!--                  <input id="srsp_hour" type="text" class="form-control" placeholder="СРСП(СРМП)">-->
                  </div>
                  <div class="col-md-2">
                    <!--                  <input id="srs_hour" type="text" class="form-control" placeholder="СРС (СРМ)">-->
                    СРС(СРМ) - {{item.creditSum[6]}}
                  </div>
                  <div class="col-md-2">
                    <!--                  <input id="seminar_hour" type="text" class="form-control" placeholder="Семинар">-->
                    Семинар - {{item.creditSum[2]}}
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Кредит</label>
                  <div class="col-md-9">
                    <p>{{ item.credit }}</p>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Описание дисциплины</label>
                  <div class="col-md-9">
                    <!--                    <p>{{ item.description }}</p>-->
                    <textarea class="form-control"
                              placeholder="Описание"
                              :value="item.description"
                              @change="changeNewSyllabusData('description', $event)">
                    </textarea>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Знания</label>
                  <div class="col-md-9">
                    <!--                    <p>{{ item.knowledge }}</p>-->
                    <textarea class="form-control"
                              placeholder="Знания"
                              :value="item.knowledge"
                              @change="changeNewSyllabusData('knowledge', $event)">
                    </textarea>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Навыки</label>
                  <div class="col-md-9">
                    <!--                    <p>{{ item.skills }}</p>-->
                    <textarea class="form-control"
                              placeholder="Навыки"
                              :value="item.skills"
                              @change="changeNewSyllabusData('skills', $event)">
                    </textarea>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Умения</label>
                  <div class="col-md-9">
                    <!--                    <p>{{ item.abilities }}</p>-->
                    <textarea class="form-control"
                              placeholder="Умения"
                              :value="item.abilities"
                              @change="changeNewSyllabusData('abilities', $event)">
                    </textarea>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Методы обучения</label>
                  <div class="col-md-9">
                    <p>
                      Лекции, презентации, деловые игры, технические средства обучения, дискуссии.
                    </p>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Требования курса </label>
                  <div class="col-md-9">
                    <p>
                      1. К каждому аудиторному занятию вы должны подготовиться заранее, согласно графику, приведенному
                      ниже.
                      Подготовка задания должна быть завершена до аудиторного занятия, на котором обсуждается тема.
                    </p>
                    <p>
                      2. Задания будут распределены в течение семестра, как показано в графике дисциплины.
                    </p>
                    <p>
                      3. Задания должны выполняться в указанные сроки. Позже задания будут приняты с коэффициентом
                      (0,8-через неделю, 0,5-через две недели).
                    </p>
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Программное обеспечение</label>
                  <div class="col-md-9">
                    <textarea class="form-control"
                              placeholder="Программное обеспечение"
                              :value="item.software"
                              @change="changeNewSyllabusData('software', $event)">
                    </textarea>
                  </div>
                </div>


                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Форма экзамена</label>


                  <div class="col-md-9">

                    <div class="row mt-1" v-for="(examType, index) in teacher_form.examTypes"
                         :key="index">
                      <div class="col-md-6">
                        <div class="d-flex align-items-center">
                          <input class="form-check-input m-0" type="radio" name="exam_type"
                                 :id="examType.id" :value="examType.id" :key="examType.id"
                                 @input="changeNewSyllabusData('exam_type', $event)">
                          <label class="col-form-label ms-3 p-0" :for="examType.id"><strong>{{examType.name}}</strong></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <!--                Для MBA не будет показываться-->
                <div class="form-group row mt-4" v-if="selectedEducationDisciplineDepartmentId != 5">
                  <label class="col-md-3 col-form-label">Политика оценки</label>


                  <div class="col-md-9">

                    <div v-for="(evaluationOption, index) in evaluationOptions"
                         :key="index">

                      <label class="col-md-6 col-form-label" :for="evaluationOption.id"><strong>{{evaluationOption.name}}</strong></label>
                      <div class="col-md-3">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="evaluation_option"
                                 :id="evaluationOption.id" :value="evaluationOption.id" :key="evaluationOption.id"
                                 @input="changeNewSyllabusData('evaluation_option_id', $event)">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div id="evaluationOptionCoefficent"
                             v-for="(coefficent, index) in evaluationOption.evaluationOptionCoefficent" :key="index">
                          <p>{{coefficent.name}} - <strong>{{coefficent.coefficent_procent}}</strong></p>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label class="col-md-3 col-form-label">Оценка</label>
                  <div class="col-md-9">
                    <p>
                      Оценка знаний студентов осуществляется по балльно-рейтинговой буквенной системе с соответствующим
                      переводом в традиционную шкалу оценок.
                    </p>
                    <p>
                      <b>Шкала выставления рейтинга обучающегося:</b>
                    </p>
                    <p>
                      Ваша итоговая оценка будет рассчитываться по формуле <br>
                      <img src="https://oldmoodle.uib.kz/intranet/images/rating.png" alt="rating" width="500">
                    </p>
                    <p>
                      Ниже приведены минимальные оценки в процентах:
                    </p>
                    <table border="1" cellspacing="0" cellpadding="0">
                      <tbody>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center"><b>Оценка по буквенной системе</b></p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center"><b>Цифровой эквивалент</b></p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center"><b>Баллы (%-ное содержание)</b></p>
                        </td>
                        <td width="143" valign="top">
                          <p align="center"><b>Оценка традиционной системе</b></p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">А</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">4,0</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">95% - 100%</p>
                        </td>
                        <td width="143" rowspan="2" valign="top">
                          <p align="center">Отлично</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">А-</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">3,67</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">90% - 94%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">В+</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">3,33</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">85% - 89%</p>
                        </td>
                        <td width="143" rowspan="4" valign="top">
                          <p align="center">Хорошо</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">В</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">3,0</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">80% - 84%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">В-</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">2,67</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">75% - 79%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">С+</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">2,33</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">70% - 74%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">С</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">2,0</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">65% - 69%</p>
                        </td>
                        <td width="143" rowspan="4" valign="top">
                          <p align="center">Удовлетворительно</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center">С-</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">1,67</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">60% - 64%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center"><span lang="EN-US">D</span>+</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">1,33</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">55% - 59%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center"><span lang="EN-US">D</span>-</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">1,0</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">50% - 54%</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center"><span lang="EN-US">F</span>Х</p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">0,5</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">25% - 49%</p>
                        </td>
                        <td width="143" rowspan="2" valign="top">
                          <p align="center">Неудовлетворительно</p>
                        </td>
                      </tr>
                      <tr>
                        <td width="87" valign="top">
                          <p align="center"><span lang="EN-US">F</span></p>
                        </td>
                        <td width="85" valign="top">
                          <p align="center">0</p>
                        </td>
                        <td width="132" valign="top">
                          <p align="center">0% - 24%</p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
            </div>


          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="submitSyllabus" v-if="isDisciplineSelected">
            Создать
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeAddSyllabusModal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Add Syllabus Template Modal -->
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
  import DropdownListEducationDiscipline from "@/components/common/DropdownListEducationDiscipline.vue";

  export default {
    name: "AddSyllabusTemplateModal",
    components: {
      DropdownListEducationDiscipline
    },
    data() {
      return {
        options: [],
        isDisciplineSelected: false,
        selectedEducationDisciplineId: null,
        selectedEducationDisciplineDepartmentId: null
      }
    },
    computed: {
      ...mapState('syllabus', ['syllabus_form']),
      ...mapState('teacher', ['teacher_form']),

      evaluationOptions() {
        const evaluationOptions = this.syllabus_form.evaluationOptions
        const discipline = this.syllabus_form.educationDisciplineInfos.discipline

        const departmentId = +discipline?.department_id

        if (departmentId === 8) {
          return evaluationOptions.filter(i => +i.id !== 2)
        }

        if (departmentId === 9) {
          return evaluationOptions.filter(i => +i.id !== 1)
        }

        return evaluationOptions.filter(i => +i.id !== 1 && +i.id !== 2)
      },
    },

    methods: {
      ...mapActions('syllabus', ['SEARCH_DISCIPLINE', 'GET_EDUCATION_DISCIPLINE_BY_ID', 'POST_SYLLABUS_DATA', 'GET_EVALUATION_OPTIONS_DATA', 'GET_SYLLABUS_DATA']),
      ...mapMutations('syllabus', ['SET_NEW_SYLLABUS_DATA', 'SET_NEW_SYLLABUS_EDUCATION_DISCIPLINE_ID', 'SET_MBA_EVALUATION_OPTION']),
      async selectDiscipline() {
        this.isDisciplineSelected = true
        await this.GET_EDUCATION_DISCIPLINE_BY_ID(this.selectedEducationDisciplineId)
        await this.SET_NEW_SYLLABUS_EDUCATION_DISCIPLINE_ID(this.selectedEducationDisciplineId)
      },
      onSearch(search) {
        if (search.length > 3) {
          this.SEARCH_DISCIPLINE(search).then(json => (this.options = json));
        }
      },
      closeAddSyllabusModal() {
        this.syllabus_form.newSyllabusInfos = {}
        this.syllabus_form.educationDisciplineInfos = {}
        //this.options.length = 0

      },
      changeSelectedEducationDiscipline(e) {
        this.selectedEducationDisciplineId = e.id
        this.selectedEducationDisciplineDepartmentId = e.department_id
        if (this.selectedEducationDisciplineDepartmentId == 5) {
          this.SET_MBA_EVALUATION_OPTION()
        }
        console.log(this.selectedEducationDisciplineId, 'selectedEducationDisciplineId')
        console.log(this.selectedEducationDisciplineDepartmentId, 'selectedEducationDisciplineDepartmentId')
        //console.log(e, 'event')
        //console.log(id, 'changeSelectedEducationDisciplineId')
        //this.selectedEducationDisciplineId = id
      },
      changeNewSyllabusData(property, e, val = 'value') {
        let value = e.target[val]
        console.log(value, "value")
        this.SET_NEW_SYLLABUS_DATA({property, value})
      },
      submitSyllabus() {
        if (this.syllabus_form.newSyllabusInfos.exam_type){
          this.POST_SYLLABUS_DATA().then(res => {
            if (res.success == true) {
              this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
              this.GET_SYLLABUS_DATA()
              this.syllabus_form.newSyllabusInfos = {}
              this.syllabus_form.educationDisciplineInfos = {}
              //this.options.length = 0

            } else {
              let errorText = '';
              for (let err of res.errors[0]) {
                errorText += err.message + '\n'
              }
              this.$error({title: 'Добавление', text: errorText})
            }
          })
        } else {
          this.$error({title: 'Выберите форму экзамена!'})
        }
      }

    },
    async mounted() {
      this.GET_EVALUATION_OPTIONS_DATA()
    }
  }
</script>

<style scoped>
  .big-checkbox {
    width: 20px;
    height: 20px;
  }
</style>