<template>
  <!-- Add Syllabus Template Modal -->
  <div class="modal fade" id="updateSyllabusTemplateModal" tabindex="-1"
       aria-labelledby="updateSyllabusTemplateModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="updateSyllabusTemplateModalLabel">Обновление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <div class="col-md-12 mt-4"
               v-for="(syllabusInfo, index) in syllabus_form.syllabusInfos.filter(i => i.id == syllabus_form.updateSyllabusTemplateId)"
               :key="index">


            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Дисциплина</label>
              <div class="col-md-9">
                <p>{{syllabusInfo.educationDiscipline.name}}</p>
              </div>
            </div>

<!--            <div class="form-group row mt-4">-->
<!--              <label class="col-md-3 col-form-label">Пререквезиты</label>-->
<!--              <div class="col-md-9">-->
<!--                <textarea class="form-control"-->
<!--                          :value="syllabusInfo.discipline_prerequisite"-->
<!--                          @input="changeSyllabusData(syllabusInfo,'discipline_prerequisite', $event)">-->
<!--                    </textarea>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="form-group row mt-4">-->
<!--              <label class="col-md-3 col-form-label">Постреквезиты</label>-->
<!--              <div class="col-md-9">-->
<!--                <textarea class="form-control"-->
<!--                          :value="syllabusInfo.discipline_postrequisite"-->
<!--                          @input="changeSyllabusData(syllabusInfo,'discipline_postrequisite', $event)">-->
<!--                    </textarea>-->
<!--              </div>-->
<!--            </div>-->


            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Описание дисциплины</label>
              <div class="col-md-9">
                <!--                    <p>{{ item.description }}</p>-->
                <textarea class="form-control"
                          :value="syllabusInfo.description"
                          @change="changeSyllabusData(syllabusInfo,'description', $event)">
                    </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Знания</label>
              <div class="col-md-9">
                <!--                    <p>{{ item.knowledge }}</p>-->
                <textarea class="form-control"
                          :value="syllabusInfo.knowledge"
                          @change="changeSyllabusData(syllabusInfo,'knowledge', $event)">
                    </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Навыки</label>
              <div class="col-md-9">
                <textarea class="form-control"
                          :value="syllabusInfo.skills"
                          @change="changeSyllabusData(syllabusInfo,'skills', $event)">
                    </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Умения</label>
              <div class="col-md-9">
                <textarea class="form-control"
                          :value="syllabusInfo.abilities"
                          @change="changeSyllabusData(syllabusInfo,'abilities', $event)">
                    </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Программное обеспечение</label>
              <div class="col-md-9">
                <textarea class="form-control"
                          :value="syllabusInfo.software"
                          @change="changeSyllabusData(syllabusInfo,'software', $event)">
                    </textarea>
              </div>
            </div>

            <div class="form-group row mt-4">
              <label class="col-md-3 col-form-label">Форма экзамена</label>


              <div class="col-md-9">

                <div class="row mt-1" v-for="(examType, index) in teacher_form.examTypes"
                     :key="index">
                  <div class="col-md-6">
                    <div class="d-flex align-items-center">
                      <input class="form-check-input m-0" type="radio" name="exam_type"
                             :id="examType.id" :value="examType.id" :key="examType.id"
                             :checked="examType.id == syllabusInfo.examType?.id"
                             @input="changeSyllabusData(syllabusInfo,'exam_type', $event)">
                      <label class="col-form-label ms-3 p-0" :for="examType.id"><strong>{{examType.name}}</strong></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            Для MBA политика оценивания нет, вариант MBA, id = 6-->
            <div class="form-group row mt-4" v-if="syllabusInfo.evaluation_option_id != 6">
              <label class="col-md-3 col-form-label">Политика оценки</label>

              <div class="col-md-9">

                <div v-for="(evaluationOption, index) in evaluationOptions" :key="index">

                  <label class="col-md-6 col-form-label"
                         :for="evaluationOption.id"><strong>{{evaluationOption.name}}</strong></label>
                  <div class="col-md-3">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="evaluation_option" :id="evaluationOption.id"
                             :value="evaluationOption.id" :key="evaluationOption.id"
                             :checked="evaluationOption.id == syllabusInfo.evaluationOption.id"
                             @input="changeSyllabusData(syllabusInfo,'evaluation_option_id', $event)">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div id="evaluationOptionCoefficent"
                         v-for="(coefficent, index) in evaluationOption.evaluationOptionCoefficent" :key="index">
                      <p>{{coefficent.name}} - <strong>{{coefficent.coefficent_procent}}</strong></p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                  @click="updateSyllabus">
            Обновить
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Закрыть
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Add Syllabus Template Modal -->
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: "UpdateSyllabusTemplateModal",

    data() {
      return {}
    },
    computed: {
      ...mapState('syllabus', ['syllabus_form']),
      ...mapState('teacher', ['teacher_form']),

      evaluationOptions() {
        const evaluationOptions = this.syllabus_form.evaluationOptions
        const syllabus = this.syllabus_form.syllabusInfos.find(i => i.id == this.syllabus_form.updateSyllabusTemplateId)

        const departmentId = +syllabus?.educationDiscipline?.department_id


        if (departmentId === 8) {
          return evaluationOptions.filter(i => +i.id !== 2)
        }

        if (departmentId === 9) {
          return evaluationOptions.filter(i => +i.id !== 1)
        }

        return evaluationOptions.filter(i => +i.id !== 1 && +i.id !== 2)
      },
    },

    methods: {
      ...mapActions('syllabus', ['GET_SYLLABUS_DATA', 'PUT_SYLLABUS_DATA']),
      ...mapMutations('syllabus', ['UPDATE_SYLLABUS_DATA']),

      changeSyllabusData(item, property, e, val = 'value') {
        const value = e.target[val]
        console.log(value, "value")
        this.UPDATE_SYLLABUS_DATA({item, property, value})
      },

      async updateSyllabus() {

        await this.PUT_SYLLABUS_DATA().then(res => {
          if (res.success == true) {
            this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
            this.GET_SYLLABUS_DATA()
          } else {
            let errorText = '';
            for (let err of res.errors[0]) {
              errorText += err.message + '\n'
            }
            this.$error({title: 'Обновление', text: errorText})
          }
        })

      },

    },
    async mounted() {
    }
  }
</script>

<style scoped>
  .big-checkbox {
    width: 20px;
    height: 20px;
  }
</style>